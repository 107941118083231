import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AuthGuard, LoginGuard } from './guard';
// import { AccessDeniedComponent, DashboardComponent, LoginComponent, NotFoundComponent } from './page';
// import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
// import { AppErrorComponent } from './pages/app.error.component';
// import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppLoginComponent } from './pages/app.login.component';


// demo
// import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
// import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
// import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
// import { MediaDemoComponent } from './demo/view/mediademo.component';
// import { MenusDemoComponent } from './demo/view/menusdemo.component';
// import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
// import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
// import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
// import { FileDemoComponent } from './demo/view/filedemo.component';
// import { DocumentationComponent } from './demo/view/documentation.component';

// import { InputDemoComponent } from './demo/view/inputdemo.component';
// import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
// import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
// import { ButtonDemoComponent } from './demo/view/buttondemo.component';
// import { TableDemoComponent } from './demo/view/tabledemo.component';
// import { ListDemoComponent } from './demo/view/listdemo.component';
// import { TreeDemoComponent } from './demo/view/treedemo.component';
// import { DisplayComponent } from './utilities/display.component';
// import { ElevationComponent } from './utilities/elevation.component';
// import { FlexboxComponent } from './utilities/flexbox.component';
// import { GridComponent } from './utilities/grid.component';
// import { IconsComponent } from './utilities/icons.component';
// import { WidgetsComponent } from './utilities/widgets.component';
// import { SpacingComponent } from './utilities/spacing.component';
// import { TypographyComponent } from './utilities/typography.component';
// import { TextComponent } from './utilities/text.component';
// import { AppCrudComponent } from './pages/app.crud.component';
// import { AppCalendarComponent } from './pages/app.calendar.component';
// import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
// import { AppInvoiceComponent } from './pages/app.invoice.component';
// import { AppHelpComponent } from './pages/app.help.component';

let storageUser = localStorage.getItem('RUdJRVJBTURBTg==')
let redirect = null
if(storageUser){
    redirect = JSON.parse(storageUser).kelompokUser.redirect
}

@NgModule({
    imports: [
        RouterModule.forRoot([
            { canActivate: [LoginGuard], path: 'login', component: AppLoginComponent },
            { path: 'bed-online', loadChildren: () => import('./page/module/bed/bed-online/bed-online.module').then(m => m.BedOnlineModule) },
            // { path: 'reservasi', loadChildren: () => import('./page/module/reservasi/reservasi.module').then(m => m.ReservasiModule) },
            { path: 'reservasi', loadChildren: () => import('./page/module/reservasi-rev/reservasi-rev.module').then(m => m.ReservasiRevModule) },
            { path: 'display', loadChildren: () => import('./page/module/viewer/display-antrian/display-antrian.module').then(m => m.DisplayAntrianModule) },
            { path: 'home-display', loadChildren: () => import('./page/module/viewer/home-display/home-display.module').then(m => m.HomeDisplayModule) },
            { path: 'display-poli/:ruanganid', loadChildren: () => import('./page/module/viewer/display-poli/display-poli.module').then(m => m.DisplayPoliModule) },
            { path: 'viewer-poli/:ruanganid', loadChildren: () => import('./page/module/viewer/viewer-poli-ta/viewer-poli-ta.module').then(m => m.ViewerPoliTaModule) },
            { path: 'display-farmasi', loadChildren: () => import('./page/module/viewer/display-farmasi/display-farmasi.module').then(m => m.DisplayFarmasiModule) },
            { path: 'caller', loadChildren: () => import('./page/module/viewer/caller/caller.module').then(m => m.CallerModule) },
            {
                path: '', component: AppMainComponent,
                children: [
                    // { canActivate: [AuthGuard], path: '', component: EmptyDemoComponent },
                    { canActivate: [AuthGuard], path: '', redirectTo: redirect ?? 'dashboard-pelayanan', pathMatch:'full'  },

                    { canActivate: [AuthGuard], path: 'dashboard-pelayanan', loadChildren: () => import('./page/module/eis/dashboard-pelayanan/dashboard-pelayanan.module').then(m => m.DashboardPelayananModule) },
                    { canActivate: [AuthGuard], path: 'dashboard-keuangan', loadChildren: () => import('./page/module/eis/dashboard-keuangan/dashboard-keuangan.module').then(m => m.DashboardKeuanganModule) },
                    { canActivate: [AuthGuard], path: 'dashboard-sdm', loadChildren: () => import('./page/module/eis/dashboard-sdm/dashboard-sdm.module').then(m => m.DashboardSdmModule) },
                    { canActivate: [AuthGuard], path: 'dashboard-persediaan', loadChildren: () => import('./page/module/eis/dashboard-persediaan/dashboard-persediaan.module').then(m => m.DashboardPersediaanModule) },
                    { canActivate: [AuthGuard], path: 'dashboard-manajemen', loadChildren: () => import('./page/module/eis/dashboard-manajemen/dashboard-manajemen.module').then(m => m.DashboardManajemenModule) },
                    { canActivate: [AuthGuard], path: 'dashboard-utdrs', loadChildren: () => import('./page/module/eis/dashboard-utdrs/dashboard-utdrs.module').then(m => m.DashboardUtdrsModule) },

                    { canActivate: [AuthGuard], path: 'pasien-lama', loadChildren: () => import('./page/module/registrasi/pasien-lama/pasien-lama.module').then(m => m.PasienLamaModule) },
                    { canActivate: [AuthGuard], path: 'pasien-baru/:noRec/:idPasien/:departemen', loadChildren: () => import('./page/module/registrasi/pasien-baru/pasien-baru.module').then(m => m.PasienBaruModule) },
                    { canActivate: [AuthGuard], path: 'daftar-registrasi-pasien', loadChildren: () => import('./page/module/registrasi/daftar-registrasi-pasien/daftar-registrasi-pasien.module').then(m => m.DaftarRegistrasiPasienModule) },
                    { canActivate: [AuthGuard], path: 'registrasi-ruangan/:id', loadChildren: () => import('./page/module/registrasi/registrasi-ruangan/registrasi-ruangan.module').then(m => m.RegistrasiRuanganModule) },
                    { canActivate: [AuthGuard], path: 'detail-registrasi-pasien/:noregistrasi', loadChildren: () => import('./page/module/kasir/detail-registrasi-pasien/detail-registrasi-pasien.module').then(m => m.DetailRegistrasiPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-pulang', loadChildren: () => import('./page/module/kasir/daftar-pasien-pulang/daftar-pasien-pulang.module').then(m => m.DaftarPasienPulangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-piutang-pasien', loadChildren: () => import('./page/module/kasir/daftar-piutang-pasien/daftar-piutang-pasien.module').then(m => m.DaftarPiutangPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-dirawat', loadChildren: () => import('./page/module/kasir/daftar-pasien-dirawat/daftar-pasien-dirawat.module').then(m => m.DaftarPasienDirawatModule) },
                    { canActivate: [AuthGuard], path: 'pemakaian-asuransi/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/registrasi/pemakaian-asuransi/pemakaian-asuransi.module').then(m => m.PemakaianAsuransiModule) },
                    { canActivate: [AuthGuard], path: 'input-tindakan/:norec_rp/:norec_dpr/:norec_tp', loadChildren: () => import('./page/module/emr/input-tindakan/input-tindakan.module').then(m => m.InputTindakanModule) },
                    { canActivate: [AuthGuard], path: 'input-diagnosa/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/emr/input-diagnosa/input-diagnosa.module').then(m => m.InputDiagnosaModule) },
                    { canActivate: [AuthGuard], path: 'daftar-perjanjian', loadChildren: () => import('./page/module/registrasi/daftar-perjanjian/daftar-perjanjian.module').then(m => m.DaftarPerjanjianModule) },
                    { canActivate: [AuthGuard], path: 'pindah-pulang/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/rawatinap/pindah-pulang/pindah-pulang.module').then(m => m.PindahPulangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-mutasi', loadChildren: () => import('./page/module/registrasi/daftar-mutasi/daftar-mutasi.module').then(m => m.DaftarMutasiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-batal', loadChildren: () => import('./page/module/registrasi/daftar-pasien-batal/daftar-pasien-batal.module').then(m => m.DaftarPasienBatalModule) },
                    { canActivate: [AuthGuard], path: 'verifikasi-tagihan/:norec_rp', loadChildren: () => import('./page/module/kasir/verifikasi-tagihan/verifikasi-tagihan.module').then(m => m.VerifikasiTagihanModule) },
                    { canActivate: [AuthGuard], path: 'pembayaran-tagihan-layanan/:norec_sp/:jenislayanan', loadChildren: () => import('./page/module/kasir/pembayaran-tagihan-layanan/pembayaran-tagihan-layanan.module').then(m => m.PembayaranTagihanLayananModule) },
                    { canActivate: [AuthGuard], path: 'bayar-tagihan-pasien/:norec_sp/:jenispembayaran/:nominalbayar', loadChildren: () => import('./page/module/kasir/bayar-tagihan-pasien/bayar-tagihan-pasien.module').then(m => m.BayarTagihanPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-penerimaan-pembayaran', loadChildren: () => import('./page/module/kasir/daftar-penerimaan-pembayaran/daftar-penerimaan-pembayaran.module').then(m => m.DaftarPenerimaanPembayaranModule) },
                    { canActivate: [AuthGuard], path: 'detail-tagihan/:noregistrasi', loadChildren: () => import('./page/module/kasir/detail-tagihan/detail-tagihan.module').then(m => m.DetailTagihanModule) },
                    { canActivate: [AuthGuard], path: 'penyetoran-deposit/:norec_rp', loadChildren: () => import('./page/module/kasir/penyetoran-deposit/penyetoran-deposit.module').then(m => m.PenyetoranDepositModule) },
                    { canActivate: [AuthGuard], path: 'daftar-tagihan-piutang-pasien', loadChildren: () => import('./page/module/kasir/daftar-tagihan-piutang-pasien/daftar-tagihan-piutang-pasien.module').then(m => m.DaftarTagihanPiutangPasienModule) },
                    { canActivate: [AuthGuard], path: 'rekam-medis/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/emr/rekam-medis/rekam-medis.module').then(m => m.RekamMedisModule) },
                    { canActivate: [AuthGuard], path: 'daftar-order', loadChildren: () => import('./page/module/penunjang/daftar-order/daftar-order.module').then(m => m.DaftarOrderModule) },
                    { canActivate: [AuthGuard], path: 'hasil-laboratorium/:norec_rp/:norec_dpr/:norec_tp/:edit', loadChildren: () => import('./page/module/penunjang/hasil-laboratorium/hasil-laboratorium.module').then(m => m.HasilLaboratoriumModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-penunjang', loadChildren: () => import('./page/module/penunjang/daftar-pasien-penunjang/daftar-pasien-penunjang.module').then(m => m.DaftarPasienPenunjangModule) },
                    { canActivate: [AuthGuard], path: 'rincian-penunjang/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/penunjang/rincian-penunjang/rincian-penunjang.module').then(m => m.RincianPenunjangModule) },
                    { canActivate: [AuthGuard], path: 'rincian-penunjang-old/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/penunjang/rincian-penunjang-old/rincian-penunjang-old.module').then(m => m.RincianPenunjangOldModule) },

                    { canActivate: [AuthGuard], path: 'daftar-konsultasi', loadChildren: () => import('./page/module/emr/daftar-konsultasi/daftar-konsultasi.module').then(m => m.DaftarKonsultasiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-terdaftar', loadChildren: () => import('./page/module/registrasi/daftar-pasien-terdaftar/daftar-pasien-terdaftar.module').then(m => m.DaftarPasienTerdaftarModule) },
                    { canActivate: [AuthGuard], path: 'daftar-triase-pasien', loadChildren: () => import('./page/module/registrasi/daftar-triase-pasien/daftar-triase-pasien.module').then(m => m.DaftarTriasePasienModule) },
                    { canActivate: [AuthGuard], path: 'rekam-medis-igd/:norm/:ket', loadChildren: () => import('./page/module/emr/rekam-medis-igd/rekam-medis-igd.module').then(m => m.RekamMedisIgdModule) },
                    { canActivate: [AuthGuard], path: 'daftar-order-resep', loadChildren: () => import('./page/module/farmasi/daftar-order-resep/daftar-order-resep.module').then(m => m.DaftarOrderResepModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-farmasi', loadChildren: () => import('./page/module/farmasi/daftar-pasien-farmasi/daftar-pasien-farmasi.module').then(m => m.DaftarPasienFarmasiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-resep-pasien', loadChildren: () => import('./page/module/farmasi/daftar-resep-pasien/daftar-resep-pasien.module').then(m => m.DaftarResepPasienModule) },
                    { canActivate: [AuthGuard], path: 'transaksi-pelayanan-apotik/:norec_pd/:jenisdata', loadChildren: () => import('./page/module/farmasi/transaksi-pelayanan-apotik/transaksi-pelayanan-apotik.module').then(m => m.TransaksiPelayananApotikModule) },
                    { canActivate: [AuthGuard], path: 'input-resep-apotik/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/farmasi/input-resep-apotik/input-resep-apotik.module').then(m => m.InputResepApotikModule) },
                    { canActivate: [AuthGuard], path: 'daftar-stok-ruangan', loadChildren: () => import('./page/module/logistik/daftar-stok-ruangan/daftar-stok-ruangan.module').then(m => m.DaftarStokRuanganModule) },
                    { canActivate: [AuthGuard], path: 'daftar-stok-ruangan-history', loadChildren: () => import('./page/module/logistik/daftar-stok-ruangan-history/daftar-stok-ruangan-history.module').then(m => m.DaftarStokRuanganHistoryModule) },
                    { canActivate: [AuthGuard], path: 'input-retur-resep/:norec_resep', loadChildren: () => import('./page/module/farmasi/input-retur-resep/input-retur-resep.module').then(m => m.InputReturResepModule) },
                    { canActivate: [AuthGuard], path: 'input-retur-resep-bayar/:norec_resep', loadChildren: () => import('./page/module/farmasi/input-retur-resep-bayar/input-retur-resep-bayar.module').then(m => m.InputReturResepBayarModule) },
                    { canActivate: [AuthGuard], path: 'daftar-retur-resep', loadChildren: () => import('./page/module/farmasi/daftar-retur-resep/daftar-retur-resep.module').then(m => m.DaftarReturResepModule) },
                    { canActivate: [AuthGuard], path: 'daftar-kartu-stok', loadChildren: () => import('./page/module/logistik/daftar-kartu-stok/daftar-kartu-stok.module').then(m => m.DaftarKartuStokModule) },
                    { canActivate: [AuthGuard], path: 'daftar-resep-nonlayanan', loadChildren: () => import('./page/module/farmasi/daftar-resep-nonlayanan/daftar-resep-nonlayanan.module').then(m => m.DaftarResepNonlayananModule) },
                    { canActivate: [AuthGuard], path: 'input-resep-nonlayanan/:norec_resep', loadChildren: () => import('./page/module/farmasi/input-resep-nonlayanan/input-resep-nonlayanan.module').then(m => m.InputResepNonlayananModule) },
                    { canActivate: [AuthGuard], path: 'retur-resep-nonlayanan/:norec_resep', loadChildren: () => import('./page/module/farmasi/retur-resep-nonlayanan/retur-resep-nonlayanan.module').then(m => m.ReturResepNonlayananModule) },
                    { canActivate: [AuthGuard], path: 'daftar-tagihan-nonlayanan', loadChildren: () => import('./page/module/kasir/daftar-tagihan-nonlayanan/daftar-tagihan-nonlayanan.module').then(m => m.DaftarTagihanNonlayananModule) },
                    { canActivate: [AuthGuard], path: 'input-tindakan-nonlayanan/:norec_sp', loadChildren: () => import('./page/module/kasir/input-tindakan-nonlayanan/input-tindakan-nonlayanan.module').then(m => m.InputTindakanNonlayananModule) },
                    { canActivate: [AuthGuard], path: 'klaim-inacbg', loadChildren: () => import('./page/module/registrasi/klaim-inacbg/klaim-inacbg.module').then(m => m.KlaimInacbgModule) },
                    { canActivate: [AuthGuard], path: 'verifikasi-klaim-inacbg', loadChildren: () => import('./page/module/registrasi/verifikasi-klaim-inacbg/verifikasi-klaim-inacbg.module').then(m => m.VerifikasiKlaimInacbgModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-rj', loadChildren: () => import('./page/module/rawatjalan/daftar-pasien-rj/daftar-pasien-rj.module').then(m => m.DaftarPasienRjModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-ri', loadChildren: () => import('./page/module/rawatinap/daftar-pasien-ri/daftar-pasien-ri.module').then(m => m.DaftarPasienRiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-order-barang', loadChildren: () => import('./page/module/logistik/daftar-order-barang/daftar-order-barang.module').then(m => m.DaftarOrderBarangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-kirim-barang', loadChildren: () => import('./page/module/logistik/daftar-kirim-barang/daftar-kirim-barang.module').then(m => m.DaftarKirimBarangModule) },
                    { canActivate: [AuthGuard], path: 'input-order-barang/:norec', loadChildren: () => import('./page/module/logistik/input-order-barang/input-order-barang.module').then(m => m.InputOrderBarangModule) },
                    { canActivate: [AuthGuard], path: 'input-kirim-barang/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/input-kirim-barang/input-kirim-barang.module').then(m => m.InputKirimBarangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-order-gizi', loadChildren: () => import('./page/module/gizi/daftar-order-gizi/daftar-order-gizi.module').then(m => m.DaftarOrderGiziModule) },
                    { canActivate: [AuthGuard], path: 'stok-opname', loadChildren: () => import('./page/module/logistik/stok-opname/stok-opname.module').then(m => m.StokOpnameModule) },
                    { canActivate: [AuthGuard], path: 'daftar-so', loadChildren: () => import('./page/module/logistik/daftar-so/daftar-so.module').then(m => m.DaftarSoModule) },
                    { canActivate: [AuthGuard], path: 'penerimaan-barang-supplier/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/penerimaan-barang-supplier/penerimaan-barang-supplier.module').then(m => m.PenerimaanBarangSupplierModule) },
                    { canActivate: [AuthGuard], path: 'daftar-penerimaan-barang-supplier', loadChildren: () => import('./page/module/logistik/daftar-penerimaan-barang-supplier/daftar-penerimaan-barang-supplier.module').then(m => m.DaftarPenerimaanBarangSupplierModule) },
                    { canActivate: [AuthGuard], path: 'mapping-modul', loadChildren: () => import('./page/module/sysadmin/mapping-modul/mapping-modul.module').then(m => m.MappingModulModule) },
                    { canActivate: [AuthGuard], path: 'daftar-kirim-gizi', loadChildren: () => import('./page/module/gizi/daftar-kirim-menu/daftar-kirim-menu.module').then(m => m.DaftarKirimMenuModule) },
                    { canActivate: [AuthGuard], path: 'siklus-menu', loadChildren: () => import('./page/module/gizi/siklus-menu/siklus-menu.module').then(m => m.SiklusMenuModule) },
                    { canActivate: [AuthGuard], path: 'setting-login-user', loadChildren: () => import('./page/module/sysadmin/setting-login-user/setting-login-user.module').then(m => m.SettingLoginUserModule) },
                    { canActivate: [AuthGuard], path: 'map-ruangan-to-produk', loadChildren: () => import('./page/module/sysadmin/map-ruangan-to-produk/map-ruangan-to-produk.module').then(m => m.MapRuanganToProdukModule) },
                    { canActivate: [AuthGuard], path: 'master-produk', loadChildren: () => import('./page/module/sysadmin/master-produk/master-produk.module').then(m => m.MasterProdukModule) },
                    { canActivate: [AuthGuard], path: 'daftar-penerimaan-kasir', loadChildren: () => import('./page/module/bendaharapenerimaan/daftar-penerimaan-kasir/daftar-penerimaan-kasir.module').then(m => m.DaftarPenerimaanKasirModule) },
                    { canActivate: [AuthGuard], path: 'setoran-kasir-harian', loadChildren: () => import('./page/module/bendaharapenerimaan/setoran-kasir-harian/setoran-kasir-harian.module').then(m => m.SetoranKasirHarianModule) },
                    { canActivate: [AuthGuard], path: 'daftar-setoran-kasir', loadChildren: () => import('./page/module/bendaharapenerimaan/daftar-setoran-kasir/daftar-setoran-kasir.module').then(m => m.DaftarSetoranKasirModule) },
                    { canActivate: [AuthGuard], path: 'input-master-produk/:idproduk', loadChildren: () => import('./page/module/sysadmin/input-master-produk/input-master-produk.module').then(m => m.InputMasterProdukModule) },
                    { canActivate: [AuthGuard], path: 'input-master-produk-apotik/:idproduk', loadChildren: () => import('./page/module/sysadmin/input-master-produk-apotik/input-master-produk-apotik.module').then(m => m.InputMasterProdukApotikModule) },
                    // { canActivate: [AuthGuard], path: 'laporan-pelayanan-obat-ruangan', loadChildren: () => import('./page/module/farmasi/laporan/laporan-pelayanan-obat-ruangan/laporan-pelayanan-obat-ruangan.module').then(m => m.LaporanPelayananObatRuanganModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-obat-ruangan', loadChildren: () => import('./page/module/farmasi/laporan/laporan-pelayanan-obat-ruangan/laporan-pelayanan-obat-ruangan.module').then(m => m.LaporanPelayananObatRuanganModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pemakaian-obat-ruangan', loadChildren: () => import('./page/module/farmasi/laporan/laporan-pemakaian-obat-ruangan/laporan-pemakaian-obat-ruangan.module').then(m => m.LaporanPemakaianObatRuanganModule) },
                    { canActivate: [AuthGuard], path: 'waktu-tunggu-apotik', loadChildren: () => import('./page/module/farmasi/laporan/waktu-tunggu-apotik/waktu-tunggu-apotik.module').then(m => m.WaktuTungguApotikModule) },
                    { canActivate: [AuthGuard], path: 'master-produk-apotik', loadChildren: () => import('./page/module/sysadmin/master-produk-apotik/master-produk-apotik.module').then(m => m.MasterProdukApotikModule) },
                    { canActivate: [AuthGuard], path: 'laporan-data-pasien-klaim', loadChildren: () => import('./page/module/registrasi/laporan-data-pasien-klaim/laporan-data-pasien-klaim.module').then(m => m.LapranDataPasienKlaimModule) },

                    { canActivate: [AuthGuard], path: 'jadwal-dokter', loadChildren: () => import('./page/module/humas/jadwal-dokter/jadwal-dokter.module').then(m => m.JadwalDokterModule) },
                    { canActivate: [AuthGuard], path: 'informasi-tarif-layanan', loadChildren: () => import('./page/module/humas/informasi-tarif-layanan/informasi-tarif-layanan.module').then(m => m.InformasiTarifLayananModule) },
                    { canActivate: [AuthGuard], path: 'setting-data', loadChildren: () => import('./page/module/sysadmin/setting-data/setting-data.module').then(m => m.SettingDataModule) },
                    { canActivate: [AuthGuard], path: 'rs-online', loadChildren: () => import('./page/module/registrasi/rs-online/rs-online.module').then(m => m.RsOnlineModule) },
                    { canActivate: [AuthGuard], path: 'form_rl_1', loadChildren: () => import('./page/module/registrasi/form-rl-satu/form-rl-satu.module').then(m => m.FormRlSatuModule) },
                    { canActivate: [AuthGuard], path: 'form_rl_2', loadChildren: () => import('./page/module/registrasi/form-rl-dua/form-rl-dua.module').then(m => m.FormRlDuaModule) },
                    { canActivate: [AuthGuard], path: 'form_rl_3', loadChildren: () => import('./page/module/registrasi/form-rl-tiga/form-rl-tiga.module').then(m => m.FormRlTigaModule) },
                    { canActivate: [AuthGuard], path: 'form_rl_4', loadChildren: () => import('./page/module/registrasi/form-rl-empat/form-rl-empat.module').then(m => m.FormRlEmpatModule) },
                    { canActivate: [AuthGuard], path: 'form_rl_5', loadChildren: () => import('./page/module/registrasi/form-rl-lima/form-rl-lima.module').then(m => m.FormRlLimaModule) },
                    { canActivate: [AuthGuard], path: 'sismadak', loadChildren: () => import('./page/module/registrasi/sismadak/sismadak.module').then(m => m.SismadakModule) },
                    { canActivate: [AuthGuard], path: 'sisrute', loadChildren: () => import('./page/module/registrasi/sisrute/sisrute.module').then(m => m.SisruteModule) },
                    { canActivate: [AuthGuard], path: 'bpjs-tools', loadChildren: () => import('./page/module/registrasi/bpjs-tools/bpjs-tools.module').then(m => m.BpjsToolsModule) },
                    { canActivate: [AuthGuard], path: 'laporan-kunjungan', loadChildren: () => import('./page/module/registrasi/laporan/laporan-kunjungan/laporan-kunjungan.module').then(m => m.LaporanKunjunganModule) },
                    { canActivate: [AuthGuard], path: 'daftar-survey', loadChildren: () => import('./page/module/humas/daftar-survey/daftar-survey.module').then(m => m.DaftarSurveyModule) },
                    { canActivate: [AuthGuard], path: 'master-ruangan', loadChildren: () => import('./page/module/sysadmin/master-ruangan/master-ruangan.module').then(m => m.MasterRuanganModule) },
                    { canActivate: [AuthGuard], path: 'map-administrasi-otomatis', loadChildren: () => import('./page/module/sysadmin/map-administrasi-otomatis/map-administrasi-otomatis.module').then(m => m.MapAdministrasiOtomatisModule) },
                    { canActivate: [AuthGuard], path: 'input-master-instalasi-ruangan', loadChildren: () => import('./page/module/sysadmin/instalasi-ruangan/instalasi-ruangan.module').then(m => m.InstalasiRuanganModule) },
                    { canActivate: [AuthGuard], path: 'input-master-provinsi', loadChildren: () => import('./page/module/sysadmin/input-provinsi/input-provinsi.module').then(m => m.InputProvinsiModule) },
                    { canActivate: [AuthGuard], path: 'input-master-kab-kota', loadChildren: () => import('./page/module/sysadmin/input-kab-kota/input-kab-kota.module').then(m => m.InputKabKotaModule) },
                    { canActivate: [AuthGuard], path: 'harga-pelayanan', loadChildren: () => import('./page/module/sysadmin/harga-pelayanan/harga-pelayanan.module').then(m => m.HargaPelayananModule) },
                    { canActivate: [AuthGuard], path: 'data-profil-rs', loadChildren: () => import('./page/module/sysadmin/data-profil-rs/data-profil-rs.module').then(m => m.DataProfilRsModule) },
                    { canActivate: [AuthGuard], path: 'data-pegawai', loadChildren: () => import('./page/module/sysadmin/data-pegawai/data-pegawai.module').then(m => m.DataPegawaiModule) },
                    { canActivate: [AuthGuard], path: 'rujukan-bpjs', loadChildren: () => import('./page/module/registrasi/rujukan-bpjs/rujukan-bpjs.module').then(m => m.RujukanBpjsModule) },
                    { canActivate: [AuthGuard], path: 'slotting-kiosk', loadChildren: () => import('./page/module/sysadmin/slotting-kiosk/slotting-kiosk.module').then(m => m.SlottingKioskModule) },
                    { canActivate: [AuthGuard], path: 'penerimaan-darah', loadChildren: () => import('./page/module/utdrs/penerimaan-darah/penerimaan-darah.module').then(m => m.PenerimaanDarahModule) },
                    { canActivate: [AuthGuard], path: 'daftar-calon-pendonor-darah', loadChildren: () => import('./page/module/utdrs/daftar-calon-pendonor-darah/daftar-calon-pendonor-darah.module').then(m => m.DaftarCalonPendonorDarahModule) },
                    { canActivate: [AuthGuard], path: 'daftar-permintaan-darah', loadChildren: () => import('./page/module/utdrs/daftar-permintaan-darah/daftar-permintaan-darah.module').then(m => m.DaftarPermintaanDarahModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pemakaian-darah', loadChildren: () => import('./page/module/utdrs/daftar-pemakaian-darah/daftar-pemakaian-darah.module').then(m => m.DaftarPemakaianDarahModule) },
                    { canActivate: [AuthGuard], path: 'master-rekanan', loadChildren: () => import('./page/module/sysadmin/master-rekanan/master-rekanan.module').then(m => m.MasterRekananModule) },
                    { canActivate: [AuthGuard], path: 'konversi-satuan', loadChildren: () => import('./page/module/sysadmin/konversi-satuan/konversi-satuan.module').then(m => m.KonversiSatuanModule) },
                    { canActivate: [AuthGuard], path: 'map-nilai-normal', loadChildren: () => import('./page/module/sysadmin/map-nilai-normal/map-nilai-normal.module').then(m => m.MapNilaiNormalModule) },
                    { canActivate: [AuthGuard], path: 'master-jenis-tindakan-kedokteran', loadChildren: () => import('./page/module/sysadmin/jenis-tindakan-kedokteran/jenis-tindakan-kedokteran.module').then(m => m.JenisTindakanKedokteranModule) },

                    { canActivate: [AuthGuard], path: 'pemesanan-barang/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/pemesanan-barang/pemesanan-barang.module').then(m => m.PemesananBarangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pemesanan-barang', loadChildren: () => import('./page/module/logistik/daftar-pemesanan-barang/daftar-pemesanan-barang.module').then(m => m.DaftarPemesananBarangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-meninggal', loadChildren: () => import('./page/module/registrasi/daftar-pasien-meninggal/daftar-pasien-meninggal.module').then(m => m.DaftarPasienMeninggalModule) },
                    { canActivate: [AuthGuard], path: 'master-diagnosa', loadChildren: () => import('./page/module/sysadmin/master-diagnosa/master-diagnosa.module').then(m => m.MasterDiagnosaModule) },
                    { canActivate: [AuthGuard], path: 'input-alkes/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/farmasi/input-alkes/input-alkes.module').then(m => m.InputAlkesModule) },
                    { canActivate: [AuthGuard], path: 'daftar-alkes-pasien', loadChildren: () => import('./page/module/farmasi/daftar-alkes-pasien/daftar-alkes-pasien.module').then(m => m.DaftarAlkesPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-bayi-lahir', loadChildren: () => import('./page/module/registrasi/daftar-pasien-bayi-lahir/daftar-pasien-bayi-lahir.module').then(m => m.DaftarPasienBayiLahirModule) },
                    { canActivate: [AuthGuard], path: 'map-jenispetugas-jenispegawai', loadChildren: () => import('./page/module/sysadmin/map-jenispetugas-jenispegawai/map-jenispetugas-jenispegawai.module').then(m => m.MapJenispetugasJenispegawaiModule) },
                    { canActivate: [AuthGuard], path: 'status-pasien', loadChildren: () => import('./page/module/sysadmin/status-pasien/status-pasien.module').then(m => m.StatusPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-registrasi-pasienlama', loadChildren: () => import('./page/module/registrasi/daftar-registrasi-pasienlama/daftar-registrasi-pasienlama.module').then(m => m.DaftarRegistrasiPasienlamaModule) },
                    { canActivate: [AuthGuard], path: 'map-akomodasi-otomatis', loadChildren: () => import('./page/module/sysadmin/map-akomodasi-otomatis/map-akomodasi-otomatis.module').then(m => m.MapAkomodasiOtomatisModule) },
                    { canActivate: [AuthGuard], path: 'map-paket', loadChildren: () => import('./page/module/sysadmin/map-paket/map-paket.module').then(m => m.MapPaketModule) },
                    { canActivate: [AuthGuard], path: 'map-laporan-rl', loadChildren: () => import('./page/module/sysadmin/map-laporan-rl/map-laporan-rl.module').then(m => m.MapLaporanRlModule) },
                    { canActivate: [AuthGuard], path: 'daftar-tagihan-pasien', loadChildren: () => import('./page/module/kasir/daftar-tagihan-pasien/daftar-tagihan-pasien.module').then(m => m.DaftarTagihanPasienModule) },
                    { canActivate: [AuthGuard], path: 'daftar-aftap', loadChildren: () => import('./page/module/utdrs/daftar-aftap/daftar-aftap.module').then(m => m.DaftarAftapModule) },
                    { canActivate: [AuthGuard], path: 'daftar-registrasi-pendonor', loadChildren: () => import('./page/module/utdrs/daftar-registrasi-pendonor/daftar-registrasi-pendonor.module').then(m => m.DaftarRegistrasiPendonorModule) },
                    { canActivate: [AuthGuard], path: 'input-master-data-global', loadChildren: () => import('./page/module/sysadmin/input-master-data-global/input-master-data-global.module').then(m => m.InputMasterGlobalModule) },
                    //                 { canActivate: [AuthGuard], path: 'input-master-kec',  loadChildren: () =>import('./page/module/sysadmin/input-kecamatan/input-kecamatan.module') .then(m => m.InputKecamatanModule) },
                    { canActivate: [AuthGuard], path: 'daftar-order-darah', loadChildren: () => import('./page/module/utdrs/daftar-order-darah/daftar-order-darah.module').then(m => m.DaftarOrderDarahModule) },
                    { canActivate: [AuthGuard], path: 'detail-crossmatch/:norec/:norec_alkes', loadChildren: () => import('./page/module/utdrs/detail-crossmatch/detail-crossmatch.module').then(m => m.DetailCrossmatchModule) },
                    { canActivate: [AuthGuard], path: 'daftar-crossmatch', loadChildren: () => import('./page/module/utdrs/daftar-crossmatch/daftar-crossmatch.module').then(m => m.DaftarCrossmatchModule) },

                    { canActivate: [AuthGuard], path: 'detail-transfusi/:norec/:norec_transfusi', loadChildren: () => import('./page/module/utdrs/detail-transfusi/detail-transfusi.module').then(m => m.DetailTransfusiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-transfusi', loadChildren: () => import('./page/module/utdrs/daftar-transfusi/daftar-transfusi.module').then(m => m.DaftarTransfusiModule) },

                    //                      { canActivate: [AuthGuard], path: 'daftar-order-darah', loadChildren: () => import('./page/module/utdrs/daftar-order-darah/daftar-order-darah.module').then(m => m.DaftarOrderDarahModule) },
                    // { canActivate: [AuthGuard], path: 'input-master-kec', loadChildren: () => import('./page/module/sysadmin/input-kecamatan/input-kecamatan.module').then(m => m.InputKecamatanModule) },
                    //                 { canActivate: [AuthGuard], path: 'input-master-kel-des',  loadChildren: () =>import('./page/module/sysadmin/input-kel-desa/input-kel-desa.module') .then(m => m.InputKelDesaModule) },

                    // //nambah ieu
                    { canActivate: [AuthGuard], path: 'jurnal', loadChildren: () => import('./page/module/akuntansi/jurnal/jurnal.module').then(m => m.JurnalModule) },
                    { canActivate: [AuthGuard], path: 'send-notif', loadChildren: () => import('./page/module/sysadmin/send-notif/send-notif.module').then(m => m.SendNotifModule) },

                    { canActivate: [AuthGuard], path: 'laporan-radiologi', loadChildren: () => import('./page/module/laporan/laporan-radiologi/laporan-radiologi.module').then(m => m.LaporanRadiologiModule) },

                    { canActivate: [AuthGuard], path: 'print-bukti-order-barang/:norec/:petugasmengetahui/:petugasmeminta/:jabatanmengetahui/:jabatanmeminta', loadChildren: () => import('./page/module/report/logistik/print-bukti-order-barang/print-bukti-order-barang.module').then(m => m.PrintBuktiOrderBarangModule) },
                    { canActivate: [AuthGuard], path: 'lap-topten-diagnosa', loadChildren: () => import('./page/module/report/registrasi/lap-topten-diagnosa/lap-topten-diagnosa.module').then(m => m.LapToptenDiagnosaModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pendapatan-kasir', loadChildren: () => import('./page/module/kasir/laporan-pendapatan-kasir/laporan-pendapatan-kasir.module').then(m => m.LaporanPendapatanKasirModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-ruangan', loadChildren: () => import('./page/module/kasir/laporan-pelayanan-ruangan/laporan-pelayanan-ruangan.module').then(m => m.LaporanPelayananRuanganModule) },
                    { canActivate: [AuthGuard], path: 'laporan-jumlah-pasien-rekanan', loadChildren: () => import('./page/module/registrasi/laporan-jumlah-pasien-rekanan/laporan-jumlah-pasien-rekanan.module').then(m => m.LaporanJumlahPasienRekananModule) },
                    { canActivate: [AuthGuard], path: 'laporan-jumlah-pasien-rujukan', loadChildren: () => import('./page/module/registrasi/laporan-jumlah-pasien-rujukan/laporan-jumlah-pasien-rujukan.module').then(m => m.LaporanJumlahPasienRujukanModule) },
                    { canActivate: [AuthGuard], path: 'laporan-diagnosa-pasien', loadChildren: () => import('./page/module/registrasi/laporan-diagnosa-pasien/laporan-diagnosa-pasien.module').then(m => m.LaporanDiagnosaPasienModule) },
                    { canActivate: [AuthGuard], path: 'laporan-jumlah-pasien-umur', loadChildren: () => import('./page/module/registrasi/laporan-jumlah-pasien-umur/laporan-jumlah-pasien-umur.module').then(m => m.LaporanJumlahPasienUmurModule) },
                    { canActivate: [AuthGuard], path: 'laporan-jumlah-pasien-ruangan', loadChildren: () => import('./page/module/registrasi/laporan-jumlah-pasien-ruangan/laporan-jumlah-pasien-ruangan.module').then(m => m.LaporanJumlahPasienRuanganModule) },
                    { canActivate: [AuthGuard], path: 'laporan-jumlah-pasien-ruangan', loadChildren: () => import('./page/module/registrasi/laporan-jumlah-pasien-ruangan/laporan-jumlah-pasien-ruangan.module').then(m => m.LaporanJumlahPasienRuanganModule) },
                    { canActivate: [AuthGuard], path: 'laporan-kunjungan-berdasarkan-asal-pasien', loadChildren: () => import('./page/module/registrasi/laporan-kunjungan-berdasarkan-asal-pasien/laporan-kunjungan-berdasarkan-asal-pasien.module').then(m => m.LaporanKunjunganBerdasarkanAsalPasienModule) },
                    { canActivate: [AuthGuard], path: 'lap-jumlah-tindakan-kelompok-pasien', loadChildren: () => import('./page/module/report/registrasi/lap-jumlah-tindakan-kelompok-pasien/lap-jumlah-tindakan-kelompok-pasien.module').then(m => m.LapJumlahTindakanKelompokPasienModule) },


                    { canActivate: [AuthGuard], path: 'daftar-logging-user', loadChildren: () => import('./page/module/sysadmin/daftar-logging-user/daftar-logging-user.module').then(m => m.DaftarLoggingUserModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-igd', loadChildren: () => import('./page/module/rawatjalan/daftar-pasien-igd/daftar-pasien-igd.module').then(m => m.DaftarPasienIGDModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pasien-indeks-rm', loadChildren: () => import('./page/module/report/registrasi/lap-indeks-rm/lap-indeks-rm.module').then(m => m.LapIndeksRmModule) },

                    { canActivate: [AuthGuard], path: 'surat-keterangan-hamil', loadChildren: () => import('./page/module/perawat/surat-keterangan-hamil/surat-keterangan-hamil.module').then(m => m.SuratKeteranganHamilModule) },
                    { canActivate: [AuthGuard], path: 'permintaan-perawatan-hamil-pns', loadChildren: () => import('./page/module/perawat/permintaan-pemeriksaan-hamil-pns/permintaan-pemeriksaan-hamil-pns.module').then(m => m.PermintaanPemeriksaanHamilPnsModule) },
                    { canActivate: [AuthGuard], path: 'laporan-blangko-rajal', loadChildren: () => import('./page/module/kasir/laporan-blangko-rajal/laporan-blangko-rajal.module').then(m => m.LaporanBlangkoRajalModule) },

                    //** TRUCKING */
                    { canActivate: [AuthGuard], path: 'g-setting-budget', loadChildren: () => import('./page/trucking/master/setting-budget/setting-budget.module').then(m => m.SettingBudgetModule) },
                    { canActivate: [AuthGuard], path: 'g-depo', loadChildren: () => import('./page/trucking/master/depo/depo.module').then(m => m.DepoModule) },
                    { canActivate: [AuthGuard], path: 'g-port', loadChildren: () => import('./page/trucking/master/port/port.module').then(m => m.PortModule) },
                    { canActivate: [AuthGuard], path: 'g-loading-location', loadChildren: () => import('./page/trucking/master/load-location/load-location.module').then(m => m.LoadLocationModule) },
                    { canActivate: [AuthGuard], path: 'g-item-budget', loadChildren: () => import('./page/trucking/master/m-item/m-item.module').then(m => m.MItemModule) },
                    { canActivate: [AuthGuard], path: 'g-fleet-type', loadChildren: () => import('./page/trucking/master/fleet-type/fleet-type.module').then(m => m.FleetTypeModule) },
                    //** END TRUCKING */


                    { canActivate: [AuthGuard], path: 'surat-keterangan-kehamilan', loadChildren: () => import('./page/module/emr/surat/surat-keterangan-kehamilan/surat-keterangan-kehamilan.module').then(m => m.SuratKeteranganKehamilanModule) },
                    { canActivate: [AuthGuard], path: 'surat-hasil-lab', loadChildren: () => import('./page/module/emr/surat/surat-hasil-lab/surat-hasil-lab.module').then(m => m.SuratHasilLabModule) },
                    { canActivate: [AuthGuard], path: 'riwayat-hamil-swasta', loadChildren: () => import('./page/module/emr/surat/riwayat-hamil-swasta/riwayat-hamil-swasta.module').then(m => m.RiwayatHamilSwastaModule) },
                    { canActivate: [AuthGuard], path: 'surat-keterangan-tht', loadChildren: () => import('./page/module/emr/surat/surat-keterangan-tht/surat-keterangan-tht.module').then(m => m.SuratKeteranganThtModule) },
                    { canActivate: [AuthGuard], path: 'surat-keterangan-sakit', loadChildren: () => import('./page/module/emr/surat/surat-keterangan-sakit/surat-keterangan-sakit.module').then(m => m.SuratKeteranganSakitModule) },
                    { canActivate: [AuthGuard], path: 'surat-keterangan-opname', loadChildren: () => import('./page/module/emr/surat/surat-keterangan-opname/surat-keterangan-opname.module').then(m => m.SuratKeteranganOpnameModule) },
                    { canActivate: [AuthGuard], path: 'surat-kematian', loadChildren: () => import('./page/module/emr/surat/surat-kematian/surat-kematian.module').then(m => m.SuratKematianModule) },
                    { canActivate: [AuthGuard], path: 'surat-keterangan-kelahiran', loadChildren: () => import('./page/module/emr/surat/surat-keterangan-kelahiran/surat-keterangan-kelahiran.module').then(m => m.SuratKeteranganKelahiranModule) },


                    { canActivate: [AuthGuard], path: 'surat-rujuk-balik', loadChildren: () => import('./page/module/emr/surat/surat-rujukan-balik/surat-rujukan-balik.module').then(m => m.SuratRujukanBalikModule) },
                    { canActivate: [AuthGuard], path: 'surat-kontrol', loadChildren: () => import('./page/module/emr/surat/surat-kontrol/surat-kontrol.module').then(m => m.SuratKontrolModule) },
                    { canActivate: [AuthGuard], path: 'surat-rujukan', loadChildren: () => import('./page/module/emr/surat/surat-rujukan/surat-rujukan.module').then(m => m.SuratRujukanModule) },

                    { canActivate: [AuthGuard], path: 'surat-rujukan-umum', loadChildren: () => import('./page/module/emr/surat/surat-rujukan-umum/surat-rujukan-umum.module').then(m => m.SuratRujukanUmumModule) },
                    { canActivate: [AuthGuard], path: 'surat-dokter', loadChildren: () => import('./page/module/emr/surat/surat-dokter/surat-dokter.module').then(m => m.SuratDokterModule) },

                    { canActivate: [AuthGuard], path: 'riwayat-kehamilan-pns', loadChildren: () => import('./page/module/perawat/riwayat-kehamilan-pns/riwayat-kehamilan-pns.module').then(m => m.RiwayatKehamilanPnsModule) },
                    { canActivate: [AuthGuard], path: 'laporan-indeks-penyakit-rj', loadChildren: () => import('./page/module/registrasi/laporan/laporan-indeks-penyakit-rj/laporan-indeks-penyakit-rj.module').then(m => m.LaporanIndeksPenyakitRJModule) },

                    // { canActivate: [AuthGuard], path: 'laporan-indeks-penyakit-rj', loadChildren: () => import('./page/module/registrasi/laporan/laporan-indeks-penyakit-rj/laporan-indeks-penyakit-rj.module').then(m => m.LaporanIndeksPenyakitRJModule) },
                    { canActivate: [AuthGuard], path: 'setting-harga-farmasi', loadChildren: () => import('./page/module/sysadmin/setting-harga-farmasi/setting-harga-farmasi.module').then(m => m.SettingHargaFarmasiModule) },
                    { canActivate: [AuthGuard], path: 'laporan-penerimaan-faktur', loadChildren: () => import('./page/module/report/logistik/laporan-penerimaan-faktur/laporan-penerimaan-faktur.module').then(m => m.LaporanPenerimaanFakturModule) },
                    { canActivate: [AuthGuard], path: 'laporan-penerimaan-faktur-detail', loadChildren: () => import('./page/module/report/logistik/laporan-penerimaan-faktur-detail/laporan-penerimaan-faktur-detail.module').then(m => m.LaporanPenerimaanFakturDetailModule) },
                    { canActivate: [AuthGuard], path: 'laporan-barang-expired', loadChildren: () => import('./page/module/report/logistik/laporan-barang-expired/laporan-barang-expired.module').then(m => m.LaporanBarangExpiredModule) },
                    { canActivate: [AuthGuard], path: 'fast-slow-moving', loadChildren: () => import('./page/module/logistik/fast-slow-moving/fast-slow-moving.module').then(m => m.FastSlowMovingModule) },
                    { canActivate: [AuthGuard], path: 'stok-minimal', loadChildren: () => import('./page/module/logistik/stok-minimal/stok-minimal.module').then(m => m.StokMinimalModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pembelian-barang', loadChildren: () => import('./page/module/report/farmasi/laporan-pembelian-barang/laporan-pembelian-barang.module').then(m => m.LaporanPembelianBarangModule) },
                    { canActivate: [AuthGuard], path: 'retur-penerimaan-barang-supplier/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/retur-penerimaan-barang-supplier/retur-penerimaan-barang-supplier.module').then(m => m.ReturPenerimaanBarangSupplierModule) },
                    { canActivate: [AuthGuard], path: 'daftar-retur-penerimaan-barang-supplier', loadChildren: () => import('./page/module/logistik/daftar-retur-penerimaan-barang-supplier/daftar-retur-penerimaan-barang-supplier.module').then(m => m.DaftarReturPenerimaanBarangSupplierModule) },
                    { canActivate: [AuthGuard], path: 'laporan-distribusi-barang', loadChildren: () => import('./page/module/report/logistik/laporan-distribusi-barang/laporan-distribusi-barang.module').then(m => m.LaporanDistribusiBarangModule) },
                    { canActivate: [AuthGuard], path: 'laporan-retur-pasien', loadChildren: () => import('./page/module/report/farmasi/laporan-retur-pasien/laporan-retur-pasien.module').then(m => m.LaporanReturPasienModule) },
                    { canActivate: [AuthGuard], path: 'laporan-penjualan-obat', loadChildren: () => import('./page/module/report/farmasi/laporan-penjualan-obat/laporan-penjualan-obat.module').then(m => m.LaporanPenjualanObatModule) },
                    { canActivate: [AuthGuard], path: 'waktu-tunggu-rj', loadChildren: () => import('./page/module/registrasi/waktu-tunggu-rj/waktu-tunggu-rj.module').then(m => m.WaktuTungguRjModule) },
                    { canActivate: [AuthGuard], path: 'master-jabatan', loadChildren: () => import('./page/module/sdm/master-jabatan/master-jabatan.module').then(m => m.MasterJabatanModule) },
                    { canActivate: [AuthGuard], path: 'master-golongan-pegawai', loadChildren: () => import('./page/module/sdm/master-golongan-pegawai/master-golongan-pegawai.module').then(m => m.MasterGolonganPegawaiModule) },
                    { canActivate: [AuthGuard], path: 'master-golongan-darah', loadChildren: () => import('./page/module/sdm/master-golongan-darah/master-golongan-darah.module').then(m => m.MasterGolonganDarahModule) },
                    { canActivate: [AuthGuard], path: 'master-eselon', loadChildren: () => import('./page/module/sdm/master-eselon/master-eselon.module').then(m => m.MasterEselonModule) },
                    { canActivate: [AuthGuard], path: 'master-kelompok-pegawai', loadChildren: () => import('./page/module/sdm/master-kelompok-pegawai/master-kelompok-pegawai.module').then(m => m.MasterKelompokPegawaiModule) },
                    { canActivate: [AuthGuard], path: 'master-pendidikan', loadChildren: () => import('./page/module/sdm/master-pendidikan/master-pendidikan.module').then(m => m.MasterPendidikanModule) },
                    { canActivate: [AuthGuard], path: 'master-status-perkawinan', loadChildren: () => import('./page/module/sdm/master-status-perkawinan/master-status-perkawinan.module').then(m => m.MasterStatusPerkawinanModule) },
                    { canActivate: [AuthGuard], path: 'master-jenis-pegawai', loadChildren: () => import('./page/module/sdm/master-jenis-pegawai/master-jenis-pegawai.module').then(m => m.MasterJenisPegawaiModule) },
                    { canActivate: [AuthGuard], path: 'master-hari-libur', loadChildren: () => import('./page/module/sdm/master-hari-libur/master-hari-libur.module').then(m => m.MasterHariLiburModule) },
                    { canActivate: [AuthGuard], path: 'master-shift-kerja', loadChildren: () => import('./page/module/sdm/master-shift-kerja/master-shift-kerja.module').then(m => m.MasterShiftKerjaModule) },
                    { canActivate: [AuthGuard], path: 'master-kelompok-shift', loadChildren: () => import('./page/module/sdm/master-kelompok-shift/master-kelompok-shift.module').then(m => m.MasterKelompokShiftModule) },


                    { canActivate: [AuthGuard], path: 'laporan-stok-barang', loadChildren: () => import('./page/module/report/logistik/laporan-stok-barang/laporan-stok-barang.module').then(m => m.LaporanStokBarangModule) },
                    { canActivate: [AuthGuard], path: 'laporan-mutasi-masuk', loadChildren: () => import('./page/module/report/logistik/laporan-mutasi-masuk/laporan-mutasi-masuk.module').then(m => m.LaporanMutasiMasukModule) },
                    { canActivate: [AuthGuard], path: 'laporan-mutasi-keluar', loadChildren: () => import('./page/module/report/logistik/laporan-mutasi-keluar/laporan-mutasi-keluar.module').then(m => m.LaporanMutasiKeluarModule) },
                    { canActivate: [AuthGuard], path: 'laporan-narkotika', loadChildren: () => import('./page/module/report/farmasi/laporan-narkotika/laporan-narkotika.module').then(m => m.LaporanNarkotikaModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pemusnahan-barang', loadChildren: () => import('./page/module/logistik/daftar-pemusnahan-barang/daftar-pemusnahan-barang.module').then(m => m.DaftarPemusnahanBarangModule) },
                    { canActivate: [AuthGuard], path: 'input-pemusnahan-barang/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/input-pemusnahan-barang/input-pemusnahan-barang.module').then(m => m.InputPemusnahanBarangModule) },
                    { canActivate: [AuthGuard], path: 'daftar-barang-rusak', loadChildren: () => import('./page/module/logistik/daftar-barang-rusak/daftar-barang-rusak.module').then(m => m.DaftarBarangRusakModule) },
                    { canActivate: [AuthGuard], path: 'input-barang-rusak/:norec/:jenisdata', loadChildren: () => import('./page/module/logistik/input-barang-rusak/input-barang-rusak.module').then(m => m.InputBarangRusakModule) },
                    { canActivate: [AuthGuard], path: 'laporan-barang-rusak', loadChildren: () => import('./page/module/report/logistik/laporan-barang-rusak/laporan-barang-rusak.module').then(m => m.LaporanBarangRusakModule) },
                    { canActivate: [AuthGuard], path: 'laporan-persediaan', loadChildren: () => import('./page/module/report/logistik/laporan-persediaan/laporan-persediaan.module').then(m => m.LaporanPersediaanModule) },
                    { canActivate: [AuthGuard], path: 'master-asset', loadChildren: () => import('./page/module/sysadmin/master-asset/master-asset.module').then(m => m.MasterAssetModule) },
                    { canActivate: [AuthGuard], path: 'daftar-asset-masuk', loadChildren: () => import('./page/module/asset/daftar-asset-masuk/daftar-asset-masuk.module').then(m => m.DaftarAssetMasukModule) },
                    { canActivate: [AuthGuard], path: 'registrasi-asset/:norec/:produkidfk/:jenisdata', loadChildren: () => import('./page/module/asset/registrasi-asset/registrasi-asset.module').then(m => m.RegistrasiAssetModule) },
                    { canActivate: [AuthGuard], path: 'register-harian-kasir', loadChildren: () => import('./page/module/laporan/register-harian-kasir/register-harian-kasir.module').then(m => m.RegisterHarianKasirModule) },
                    { canActivate: [AuthGuard], path: 'daftar-barang-produksi', loadChildren: () => import('./page/module/farmasi/daftar-barang-produksi/daftar-barang-produksi.module').then(m => m.DaftarBarangProduksiModule) },
                    { canActivate: [AuthGuard], path: 'laporan-rekap-penjualan-obat', loadChildren: () => import('./page/module/laporan/farmasi/laporan-rekap-penjualan-obat/laporan-rekap-penjualan-obat.module').then(m => m.LaporanRekapPenjualanObatModule) },
                    { canActivate: [AuthGuard], path: 'laporan-rekap-penjualan-obat-dokter', loadChildren: () => import('./page/module/laporan/farmasi/laporan-rekap-penjualan-obat-dokter/laporan-rekap-penjualan-obat-dokter.module').then(m => m.LaporanRekapPenjualanObatDokterModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-gizi', loadChildren: () => import('./page/module/gizi/daftar-pasien-gizi/daftar-pasien-gizi.module').then(m => m.DaftarPasienGiziModule) },
                    { canActivate: [AuthGuard], path: 'input-barang-produksi/:norec/:jenisdata', loadChildren: () => import('./page/module/farmasi/input-barang-produksi/input-barang-produksi.module').then(m => m.InputBarangProduksiModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-penunjang', loadChildren: () => import('./page/module/laporan/laporan-pelayanan-penunjang/laporan-pelayanan-penunjang.module').then(m => m.LaporanPelayananPenunjangModule) },
                    { canActivate: [AuthGuard], path: 'master-paket-obat', loadChildren: () => import('./page/module/farmasi/master-paket-obat/master-paket-obat.module').then(m => m.MasterPaketObatModule) },
                    { canActivate: [AuthGuard], path: 'laporan-golongan-obat', loadChildren: () => import('./page/module/report/farmasi/laporan-golongan-obat/laporan-golongan-obat.module').then(m => m.LaporanGolonganObatModule) },
                    { canActivate: [AuthGuard], path: 'laporan-blangko-penyakit', loadChildren: () => import('./page/module/laporan/laporan-blangko-penyakit/laporan-blangko-penyakit.module').then(m => m.LaporanBlangkoPenyakitModule) },
                    { canActivate: [AuthGuard], path: 'laporan-blangko-rawatinap', loadChildren: () => import('./page/module/laporan/laporan-blangko-rawatinap/laporan-blangko-rawatinap.module').then(m => m.LaporanBlangkoRawatinapModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-rawat-inap', loadChildren: () => import('./page/module/laporan/laporan-pelayanan-rawat-inap/laporan-pelayanan-rawat-inap.module').then(m => m.LaporanPelayananRawatInapModule) },

                    { canActivate: [AuthGuard], path: 'daftar-registrasi-asset', loadChildren: () => import('./page/module/asset/daftar-registrasi-asset/daftar-registrasi-asset.module').then(m => m.DaftarRegistrasiAssetModule) }, { canActivate: [AuthGuard], path: 'laporan-keadaan-bayi-lahir', loadChildren: () => import('./page/module/laporan/laporan-keadaan-bayi-lahir/laporan-keadaan-bayi-lahir.module').then(m => m.LaporanKeadaanBayiLahirModule) },
                    { canActivate: [AuthGuard], path: 'absensi', loadChildren: () => import('./page/module/sdm/absensi/absensi.module').then(m => m.AbsensiModule) },
                    { canActivate: [AuthGuard], path: 'rekap-sdm', loadChildren: () => import('./page/module/sdm/rekap-sdm/rekap-sdm.module').then(m => m.RekapSdmModule) },
                    { canActivate: [AuthGuard], path: 'permohonan-cuti', loadChildren: () => import('./page/module/sdm/permohonan-cuti/permohonan-cuti.module').then(m => m.PermohonanCutiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-aset-ruangan', loadChildren: () => import('./page/module/asset/daftar-aset-ruangan/daftar-aset-ruangan.module').then(m => m.DaftarAsetRuanganModule) },
                    { canActivate: [AuthGuard], path: 'daftar-kartu-aset', loadChildren: () => import('./page/module/asset/daftar-kartu-aset/daftar-kartu-aset.module').then(m => m.DaftarKartuAsetModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-rawat-jalan', loadChildren: () => import('./page/module/laporan/laporan-pelayanan-rawat-jalan/laporan-pelayanan-rawat-jalan.module').then(m => m.LaporanPelayananRawatJalanModule) },
                    { canActivate: [AuthGuard], path: 'register-lab', loadChildren: () => import('./page/module/laporan/laboratorium/register-lab/register-lab.module').then(m => m.RegisterLabModule) },
                    { canActivate: [AuthGuard], path: 'laporan-lab-hematologi', loadChildren: () => import('./page/module/laporan/laporan-lab-hematologi/laporan-lab-hematologi.module').then(m => m.LaporanLabHematologiModule) },
                    { canActivate: [AuthGuard], path: 'input-resep-nonlayanan-v2/:norec_resep', loadChildren: () => import('./page/module/farmasi/input-resep-nonlayanan-v2/input-resep-nonlayanan-v2.module').then(m => m.InputResepNonlayananV2Module) },
                    { canActivate: [AuthGuard], path: 'input-resep-apotik-v2/:norec_rp/:norec_dpr', loadChildren: () => import('./page/module/farmasi/input-resep-apotik-v2/input-resep-apotik-v2.module').then(m => m.InputResepApotikV2Module) },


                    { canActivate: [AuthGuard], path: 'monitoring-biaya', loadChildren: () => import('./page/module/registrasi/monitoring-biaya/monitoring-biaya.module').then(m => m.MonitoringBiayaModule) },
                    { canActivate: [AuthGuard], path: 'detail-pegawai/:id', loadChildren: () => import('./page/module/sysadmin/data-pegawai/data-pegawai.module').then(m => m.DataPegawaiModule) },
                    { canActivate: [AuthGuard], path: 'lap-asal-rujukan', loadChildren: () => import('./page/module/laporan/lap-asal-rujukan/lap-asal-rujukan.module').then(m => m.LapAsalRujukanModule) },
                    { canActivate: [AuthGuard], path: 'lap-pemanfaatan-rs', loadChildren: () => import('./page/module/laporan/lap-pemanfaatan-rs/lap-pemanfaatan-rs.module').then(m => m.LapPemanfaatanRsModule) },
                    { canActivate: [AuthGuard], path: 'lap-penyakit-tm-bp', loadChildren: () => import('./page/module/laporan/lap-penyakit-tidakmenular-bp/lap-penyakit-tidakmenular-bp.module').then(m => m.LapPenyakitTidakmenularBpModule) },
                    { canActivate: [AuthGuard], path: 'satu-sehat', loadChildren: () => import('./page/module/bridging/satu-sehat/satu-sehat/satu-sehat.module').then(m => m.SatuSehatModule) },
                    { canActivate: [AuthGuard], path: 'history-satusehat/:id', loadChildren: () => import('./page/module/bridging/satu-sehat/history-satusehat/history-satusehat.module').then(m => m.HistorySatusehatModule) },
                    { canActivate: [AuthGuard], path: 'registrasi-barang-sterilisasi', loadChildren: () => import('./page/module/cssd/registrasi-barang-sterilisasi/registrasi-barang-sterilisasi.module').then(m => m.RegistrasiBarangSterilisasiModule) },
                    { canActivate: [AuthGuard], path: 'permintaan-barang-sterilisasi/:norec/:jenisdata', loadChildren: () => import('./page/module/cssd/permintaan-barang-sterilisasi/permintaan-barang-sterilisasi.module').then(m => m.PermintaanBarangSterilisasiModule) },
                    { canActivate: [AuthGuard], path: 'daftar-tagihan-ambulan', loadChildren: () => import('./page/module/ambulan/daftar-tagihan-ambulan/daftar-tagihan-ambulan.module').then(m => m.DaftarTagihanAmbulanModule) },
                    { canActivate: [AuthGuard], path: 'input-tagihan-ambulan/:norec_sp', loadChildren: () => import('./page/module/ambulan/input-tagihan-ambulan/input-tagihan-ambulan.module').then(m => m.InputTagihanAmbulanModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-ambulan', loadChildren: () => import('./page/module/ambulan/laporan-pelayanan-ambulan/laporan-pelayanan-ambulan.module').then(m => m.LaporanPelayananAmbulanModule) },
                    { canActivate: [AuthGuard], path: 'laporan-petugas-ambulan', loadChildren: () => import('./page/module/ambulan/laporan-petugas-ambulan/laporan-petugas-ambulan.module').then(m => m.LaporanPetugasAmbulanModule) },
                    { canActivate: [AuthGuard], path: 'daftar-tagihan-diklat', loadChildren: () => import('./page/module/diklat/daftar-tagihan-diklat/daftar-tagihan-diklat.module').then(m => m.DaftarTagihanDiklatModule) },
                    { canActivate: [AuthGuard], path: 'input-tagihan-diklat/:norec_sp', loadChildren: () => import('./page/module/diklat/input-tagihan-diklat/input-tagihan-diklat.module').then(m => m.InputTagihanDiklatModule) },
                    { canActivate: [AuthGuard], path: 'laporan-pelayanan-diklat', loadChildren: () => import('./page/module/diklat/laporan-pelayanan-diklat/laporan-pelayanan-diklat.module').then(m => m.LaporanPelayananDiklatModule) },
                    { canActivate: [AuthGuard], path: 'buku-kas-umum', loadChildren: () => import('./page/module/bendaharakeluar/buku-kas-umum/buku-kas-umum.module').then(m => m.BukuKasUmumModule) },
                    { canActivate: [AuthGuard], path: 'daftar-permintaan-ipsrs', loadChildren: () => import('./page/module/ipsrs/daftar-permintaan-ipsrs/daftar-permintaan-ipsrs.module').then(m => m.DaftarPermintaanIpsrsModule) },
                    { canActivate: [AuthGuard], path: 'daftar-pasien-fk', loadChildren: () => import('./page/module/farmasi-klinis/daftar-pasien-fk/daftar-pasien-fk.module').then(m => m.DaftarPasienFkModule) },
                    { canActivate: [AuthGuard], path: 'daftar-detail-fk/:normidfk/:norec_pd', loadChildren: () => import('./page/module/farmasi-klinis/daftar-detail-fk/daftar-detail-fk.module').then(m => m.DaftarDetailFkModule) },
                    { canActivate: [AuthGuard], path: 'daftar-klaim', loadChildren: () => import('./page/module/verifikator/daftar-klaim/daftar-klaim.module').then(m => m.DaftarKlaimModule) },
                    { canActivate: [AuthGuard], path: 'daftar-klaim-detail/:normidfk/:norec_pd', loadChildren: () => import('./page/module/verifikator/daftar-klaim-detail/daftar-klaim-detail.module').then(m => m.DaftarKlaimDetailModule) },
                    { canActivate: [AuthGuard], path: 'map-ruang-to-emr', loadChildren: () => import('./page/module/sysadmin/map-ruang-to-emr/map-ruang-to-emr.module').then(m => m.MapRuangToEmrModule) },
                    { canActivate: [AuthGuard], path: 'master-global', loadChildren: () => import('./page/module/sysadmin/master-global/master-global.module').then(m => m.MasterGlobalModule) },
                    { canActivate: [AuthGuard], path: 'riwayat-pengisian-emr/:normidfk', loadChildren: () => import('./page/module/emr/riwayat-pengisian-emr/riwayat-pengisian-emr.module').then(m => m.RiwayatPengisianEmrModule) },
                    { canActivate: [AuthGuard], path: 'map-jeniskegiatan-ruangan', loadChildren: () => import('./page/module/sysadmin/map-jeniskegiatan-ruangan/map-jeniskegiatan-ruangan.module').then(m => m.MapJeniskegiatanRuanganModule) },
                    { canActivate: [AuthGuard], path: 'daftar-permintaan-steril', loadChildren: () => import('./page/module/cssd/daftar-permintaan-steril/daftar-permintaan-steril.module').then(m => m.DaftarPermintaanSterilModule) },
                    { canActivate: [AuthGuard], path: 'daftar-jasa-pelayanan-dokter', loadChildren: () => import('./page/module/jaspel/daftar-jasa-pelayanan-dokter/daftar-jasa-pelayanan-dokter.module').then(m => m.DaftarJasaPelayananDokterModule) },
                    { canActivate: [AuthGuard], path: 'list-permintaan', loadChildren: () => import('./page/module/sysadmin/form-list-permintaan/form-list-permintaan.module').then(m => m.InputPermintaanModule) },
                    // demo

                    // { path: 'uikit/formlayout', component: FormLayoutDemoComponent },
                    // { path: 'uikit/floatlabel', component: FloatLabelDemoComponent },
                    // { path: 'uikit/invalidstate', component: InvalidStateDemoComponent },
                    // { path: 'uikit/input', component: InputDemoComponent },
                    // { path: 'uikit/button', component: ButtonDemoComponent },
                    // { path: 'uikit/table', component: TableDemoComponent },
                    // { path: 'uikit/list', component: ListDemoComponent },
                    // { path: 'uikit/tree', component: TreeDemoComponent },
                    // { path: 'uikit/panel', component: PanelsDemoComponent },
                    // { path: 'uikit/overlay', component: OverlaysDemoComponent },
                    // { path: 'uikit/menu', component: MenusDemoComponent },
                    // { path: 'uikit/media', component: MediaDemoComponent },
                    // { path: 'uikit/message', component: MessagesDemoComponent },
                    // { path: 'uikit/misc', component: MiscDemoComponent },
                    // { path: 'uikit/charts', component: ChartsDemoComponent },
                    // { path: 'uikit/file', component: FileDemoComponent },
                    // { path: 'utilities/display', component: DisplayComponent },
                    // { path: 'utilities/elevation', component: ElevationComponent },
                    // { path: 'utilities/flexbox', component: FlexboxComponent },
                    // { path: 'utilities/grid', component: GridComponent },
                    // { path: 'utilities/icons', component: IconsComponent },
                    // { path: 'utilities/widgets', component: WidgetsComponent },
                    // { path: 'utilities/spacing', component: SpacingComponent },
                    // { path: 'utilities/typography', component: TypographyComponent },
                    // { path: 'utilities/text', component: TextComponent },
                    // { path: 'pages/crud', component: AppCrudComponent },
                    // { path: 'pages/calendar', component: AppCalendarComponent },
                    // { path: 'pages/timeline', component: AppTimelineDemoComponent },
                    // { path: 'pages/invoice', component: AppInvoiceComponent },
                    // { path: 'pages/help', component: AppHelpComponent },
                    // { path: 'pages/empty', component: EmptyDemoComponent },
                    // { path: 'documentation', component: DocumentationComponent }

                ]
            },
            // { path: 'error', component: AppErrorComponent },
            // { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
