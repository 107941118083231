
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AppComponent} from '../app.component';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ApiService } from '../service';
import { TranslatorService } from '../service/translator.service';
import { MessageService } from 'primeng/api';
import { Config } from 'src/app/guard';
import { AlertService } from 'src/app/service/component/alert/alert.service';
import { SocketService } from '../service/socket.service';
import { NotificationService } from '../service/notification.service';
import { AppMainComponent } from '../app.main.component';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
	encapsulation: ViewEncapsulation.None,
  providers: [
    AppMainComponent,
  ],
})
export class AppLoginComponent implements OnInit {

	loading = false;
	submitted = false;
	error = '';
	bahasa: any[];
	loginGagal: string;
	pilihModulAplikasi: boolean = false;
	usernamePasswordNotNull: string;
	d_modul_aplikasi: any[];
	modul_aplikasi: any;
	item: any = {}

    constructor(public app: AppComponent,
      public appMain:AppMainComponent,
      private router: Router,
      private authService: AuthService,
      private translate: TranslateService,
      private terjemah: TranslatorService,
      private messageService: MessageService,
      private apiService: ApiService,
      private alert: AlertService,
      // private socketService: SocketService,
      // private notif: NotificationService
      ) {}

      ngOnInit() {
        this.modul_aplikasi = null
        localStorage.clear()
        sessionStorage.clear()
      }

      loginKeun() {

        this.submitted = true;
        if (!this.item.namaUser) {
          this.messageService.add({ key: 't-notif', severity: 'error', summary: 'Info', detail: 'Nama User harus di isi !' });
          this.loading = false;
          return;
        }
        if (!this.item.kataSandi) {
          this.messageService.add({ key: 't-notif', severity: 'error', summary: 'Info', detail: 'Kata Sandi harus di isi !' });
          this.loading = false;
          return;
        }

        this.loading = true;
        this.authService.login(this.item.namaUser, this.item.kataSandi, false)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.loading = false;
              // this.socketService.emit('login', JSON.stringify(data.pegawai));
              if (data.id != undefined) {
                if(data.kelompokUser.redirect){
                    this.router.navigate([data.kelompokUser.redirect]);
                }else{
                    this.router.navigate(['']);
                }

                // let namaDashboard = data.kelompokUser.kodeexternal
                // if (namaDashboard != null) {
                //   this.router.navigate(['dashboard-' + namaDashboard]);
                // } else {
                //   this.router.navigate(['']);
                // }

              } else {
                this.d_modul_aplikasi = []
                this.pilihModulAplikasi = true
                this.authService.setDataLoginUser(data)
              }

            }, (error: any) => {
              this.loading = false
            });
      }
      pilihModul(event: any) {
        this.loading = true;
      }
      batal() {
        this.pilihModulAplikasi = false
        this.d_modul_aplikasi = []
        this.item = {}
      }
}
